export default function Arrow() {
  return (
    <span className="w-20 h-20 rotate-90 sm:rotate-0 mb-4 sm:mb-0 sm:mr-14">
      <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
        <path
          className="dark:fill-slate-50"
          d="M508.16,245.603l-96-112c-4.352-5.088-11.488-6.944-17.728-4.608c-6.272,2.336-10.432,8.32-10.432,15.008v49.888
      l-369.984,46.24C6.016,241.123,0,247.939,0,256.003c0,8.064,6.016,14.88,14.016,15.872L384,318.115v49.888
      c0,6.688,4.16,12.672,10.432,15.008c1.824,0.672,3.68,0.992,5.568,0.992c4.608,0,9.056-1.984,12.16-5.6l96-112
      C513.28,260.419,513.28,251.587,508.16,245.603z"
        />
      </svg>
    </span>
  );
}
