import React from "react";
import Arrow from "./Arrow";
import Square from "./shapes/Square";
import Circle from "./shapes/Circle";
import Modals from "./Modals";

export default function Shapes() {
  const [showModal, setShowModal] = React.useState(false);
  const filterMethod = `  const fruits = ['apple', 'lemon', 'orange', 'watermelon', 'pear', 'pomegranate', 'grapefruit'];
  const basket = fruits.filter(fruit => fruit.length >= 6);
  console.log(basket);
  // expected output: Array ["orange", "watermelon", "pomegranate", "grapefruit"]`;
  const findMethod = `  const fruits = ['apple', 'lemon', 'orange', 'watermelon', 'pear', 'pomegranate', 'grapefruit'];  
  const basket = fruits.find(fruit => fruit === 'pear');
  console.log(basket);
  // expected output: pear;`;
  const findIndexMethod = `  const fruits = ['lemon','kiwi','banana'];
  const basket = fruits.findIndex((item) => item === 'kiwi');  
  // expected output: // index == 1`;
  const someMethod = `  const fruits = ["lemon", "kiwi", "banana"];  
  function checkTheFruit(arr, val) {
    return arr.some(arrVal => val === arrVal);  
  }
  // expected output:
  checkTheFruit(fruits, 'mango'); // false
  checkTheFruit(fruits, 'kiwi'); // true`;
  const everyMethod = `  const fruits = [ {healthy: true}, {healthy: false}, {healthy: true} ];
  let result = fruits.every((fruit) => {
    return fruit.healthy === true;
  })
  // expected output: console.log(result) // false`;
  const popMethod = `  const fruits = ["lemon", "kiwi", "banana"];
  fruits.pop();
  // expected output: // fruits = ["lemon", "kiwi"];  `;
  const shiftMethod = `  const fruits = ["lemon", "kiwi" ,"banana"];
  fruits.shift();
  // expected output: // fruits = ["lemon"];  `;
  const unshiftMethod = `  const fruits = ["lemon", "kiwi", "banana"];
  fruits.unshift("apple");
  // expected output: // fruits = ["apple", "lemon", "kiwi, "banana"];  `;
  const reverseMethod = `  const fruits = ["lemon", "kiwi", "banana"];
  fruits.reverse();
  // expected output: // fruits = ["banana", "kiwi, "lemon"];  `;
  const atMethod = `  const fruits = ["lemon", "kiwi", "banana"];
  fruits.at(-2);
  // expected output: // "kiwi";  `;
  const mapMethod = `  const basket = {type: "fruits", tropical: ["lemon", "kiwi", "banana"]};
  let newBasket = basket.tropical.map((fruit, index) => {
    return index + "-" + fruit;
  });
  // expected output: ['0-lemon', '1-kiwi', '2-banana'] `;
  const fillMethod = `  const fruits = ["lemon", "kiwi", "banana"];
  fruits.fill("apple", 1, 3);
  // expected output: ["lemon", "apple", "apple"] `;
  const copyWithinMethod = `  const fruits = ["lemon", "kiwi", "banana", "apple"];
  fruits.copyWithin(0, 2);
  // expected output: ["banana", "apple", "banana", "apple"] `;
  const pushMethod = `  const fruits = ["lemon", "kiwi", "banana"];
  fruits.push("apple");
  // expected output: ["lemon", "kiwi", "banana", "apple"];`;
  const reduceMethod = `  const fruits = ["lemon", "kiwi", "banana", "kiwi"];
  fruits.reduce((accumulator, fruit) => {
    if(fruit.includes("kiwi")) {
      return accumulator += 1;
    } else {
      return accumulator;}
  }, 0);
  // expected output: 2
  
  // For more about the reduce method I wrote this blog post: 
  // https://medium.com/swlh/lets-try-to-reduce-with-javascript-d2f4690569f3`;

  const filter = (e) => {
    let methodName = e.target.value.toLowerCase();

    let noResult = document.getElementsByClassName("no-result")[0];
    const dataAttrs = document.querySelectorAll("div[data-name]");

    let dataNodes = Array.from(dataAttrs);
    let check = dataNodes.every((data) => {
      return data.classList.contains("styleone");
    });

    if (check && methodName.length > 1) {
      noResult.style.display = "block";
    } else {
      noResult.style.display = "none";
    }

    [...dataAttrs].filter((dataid) => {
      const datar = dataid.getAttribute("data-name").toLowerCase();

      if (datar.indexOf(methodName) === -1 && methodName !== "") {
        dataid.classList.remove("styletwo");
        dataid.classList.add("styleone");
      } else {
        dataid.classList.remove("styleone");
        dataid.classList.add("styletwo");
      }
    });
  };

  const openModal = (event) => {
    event.preventDefault();
    const {
      target: {
        dataset: { modal }
      }
    } = event;
    if (modal) setShowModal(modal);
  };

  const closeModal = () => {
    setShowModal("");
  };

  return (
    <>
      <input
        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="searchmethod"
        type="text"
        onChange={filter}
        placeholder="Search..."
      />
      <div
        data-name="filter"
        className="flex flex-col relative sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Circle />
        <Square />
        <Square />
        <span
          data-tip="The <strong>filter()</strong> method creates a new array with all elements that pass the
        test implemented by the provided function."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .filter
        </span>{" "}
        <span className="w-8 h-8 mr-0 my-4 sm:mr-14 bracket bg-accent dark:bg-darkaccent rounded-full drop-shadow border"></span>
        <Arrow />
        <span className="w-20 h-20 square-bracket bg-accent dark:bg-darkaccent rounded-full border-solid border-4 border-borderaccent drop-shadow"></span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="filterMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>

      {showModal === "filterMethod" ? (
        <Modals name={filterMethod} close={closeModal} />
      ) : null}
      <div
        data-name="find"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub  p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Circle />
        <Square />
        <span
          data-tip="The <strong>find()</strong> method returns the value of the first element in the provided
        array that satisfies the provided testing function. <br/>If no values satisfy
        the testing function, undefined is returned."
          className="w-auto sm:w-40 mr-0 sm:mr-6 font-bold text-right text-3xl cursor-help"
        >
          {" "}
          .find
        </span>
        <span className="w-8 h-8 mr-0 my-4 sm:mr-14 bracket bg-accent dark:bg-darkaccent rounded-full drop-shadow border"></span>
        <Arrow />
        <span className="w-20 h-20 bg-accent dark:bg-darkaccent rounded-full border-solid border-4 border-borderaccent drop-shadow"></span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="findMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "findMethod" ? (
        <Modals name={findMethod} close={closeModal} />
      ) : null}
      <div
        data-name="findindex"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Square />
        <Circle />
        <span
          data-tip="The <strong>findIndex()</strong> method returns the index of the first element in the
        array that satisfies the provided testing function. <br/>Otherwise, it
        returns -1, indicating that no element passed the test."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .findIndex
        </span>
        <span className="w-8 h-8 mr-0 my-4 sm:mr-14 bracket bg-accent dark:bg-darkaccent rounded-full drop-shadow border"></span>
        <Arrow />
        <span className="w-20 text-center text-5xl font-bold">2</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="findIndexMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "findIndexMethod" ? (
        <Modals name={findIndexMethod} close={closeModal} />
      ) : null}
      <div
        data-name="some"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Circle />
        <Square />
        <span
          data-tip="The <strong>some()</strong> method tests whether at least one element in the array passes
          the test implemented by the provided function. <br />
          It returns true if, in the array, it finds an element for which the
          provided function returns true; otherwise it returns false. <br/>It doesn't
          modify the array."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .some
        </span>
        <span className="w-8 h-8 mr-0 my-4 sm:mr-14 bracket bg-accent dark:bg-darkaccent rounded-full drop-shadow border"></span>
        <Arrow />
        <span className="w-20 text-center text-4xl sm:text-5xl font-bold">
          true
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="someMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "someMethod" ? (
        <Modals name={someMethod} close={closeModal} />
      ) : null}
      <div
        data-name="every"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Circle />
        <Square />
        <span
          data-tip="The <strong>every()</strong> method tests whether all elements in the array pass the test implemented by the provided function. It returns a Boolean value."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .every
        </span>
        <span className="w-8 h-8 mr-0 my-4 sm:mr-14 bracket bg-accent dark:bg-darkaccent rounded-full drop-shadow border"></span>
        <Arrow />
        <span className="w-20 text-center text-4xl sm:text-5xl font-bold">
          false
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="everyMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "everyMethod" ? (
        <Modals name={everyMethod} close={closeModal} />
      ) : null}
      <div
        data-name="pop"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Square />
        <Circle />
        <span
          data-tip="The <strong>pop()</strong> method removes the last element from an array and returns that element. This method changes the length of the array."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .pop
        </span>
        <span className="w-2 h-8 mr-0 sm:mr-14 my-3 sm:my-0 bracket rounded-full"></span>
        <Arrow />
        <Square />
        <Square />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="popMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "popMethod" ? (
        <Modals name={popMethod} close={closeModal} />
      ) : null}
      <div
        data-name="shift"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Circle />
        <Square />
        <Square />
        <span
          data-tip="The <strong>shift()</strong> method removes the first element from an array and returns that removed element. This method changes the length of the array."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .shift
        </span>
        <span className="w-2 h-8 mr-0 sm:mr-14 my-3 sm:my-0 bracket rounded-full"></span>
        <Arrow />
        <Square />
        <Square />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="shiftMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "shiftMethod" ? (
        <Modals name={shiftMethod} close={closeModal} />
      ) : null}
      <div
        data-name="unshift"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Circle />
        <Square />
        <Square />
        <span
          data-tip="The <strong>unshift()</strong> method adds one or more elements to the beginning of an array and returns the new length of the array."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .unshift
        </span>
        <span className="w-8 h-8 mr-0 my-4 sm:mr-14 bracket bg-subaccent dark:bg-darksubaccent drop-shadow border"></span>
        <Arrow />
        <Square />
        <Circle />
        <Square />
        <Square />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="unshiftMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "unshiftMethod" ? (
        <Modals name={unshiftMethod} close={closeModal} />
      ) : null}
      <div
        data-name="reverse"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Circle />
        <Square />
        <Square />
        <span
          data-tip="The <strong>reverse()</strong> method reverses an array in place. The first array element becomes the last, and the last array element becomes the first."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .reverse
        </span>
        <span className="w-2 h-8 mr-0 sm:mr-14 my-3 sm:my-0 bracket rounded-full"></span>
        <Arrow />
        <Square />
        <Square />
        <Circle />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="reverseMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "reverseMethod" ? (
        <Modals name={reverseMethod} close={closeModal} />
      ) : null}
      <div
        data-name="at"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Square />
        <Circle />
        <Square />
        <span
          data-tip="The <strong>at()</strong> method takes an integer value and returns the item at that index, allowing for positive and negative integers. <br/>Negative integers count back from the last item in the array."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .at
        </span>
        <span className="w-8 sm:w-12 h-8 mr-0 sm:mr-14 my-3 sm:my-0 bracket rounded-full text-2xl text-center outs">
          -2
        </span>
        <Arrow />
        <Circle />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="atMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "atMethod" ? (
        <Modals name={atMethod} close={closeModal} />
      ) : null}
      <div
        data-name="map"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Square />
        <Square />
        <span
          data-tip="The <strong>map()</strong> method creates a new array populated with the results of calling a provided function on every element in the calling array."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .map
        </span>
        <span className="mr-0 sm:mr-14 my-3 sm:my-0 bracket flex justify-center items-center">
          <span className="w-8 h-8 bg-subaccent dark:bg-darksubaccent drop-shadow border"></span>
          <span className="w-8 h-8 flex ml-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </span>
          <span className="w-8 h-8 bg-accent dark:bg-darkaccent rounded-full drop-shadow border"></span>
        </span>
        <Arrow />
        <span className="square-bracket flex justify-center no-margin">
          <Circle />
          <Circle />
          <Circle />
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="mapMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "mapMethod" ? (
        <Modals name={mapMethod} close={closeModal} />
      ) : null}
      <div
        data-name="fill"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Square />
        <Square />
        <span
          data-tip="The <strong>fill()</strong> method changes all elements in an array to a static value, from a start index (default 0) to an end index (default array.length). It returns the modified array."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .fill
        </span>
        <span className="mr-0 sm:mr-14 my-3 sm:my-0 bracket flex justify-center items-center">
          <span className="w-8 h-8 bg-accent dark:bg-darkaccent rounded-full drop-shadow border"></span>
          <span className="w-8 h-8 text-2xl text-center outs">, 1</span>
        </span>
        <Arrow />
        <Square />
        <Circle />
        <Circle />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="fillMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "fillMethod" ? (
        <Modals name={fillMethod} close={closeModal} />
      ) : null}
      <div
        data-name="copywithin"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Circle />
        <Square />
        <span
          data-tip="The <strong>copyWithin()</strong> method shallow copies part of an array to another location in the same array and returns it without modifying its length."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-2xl cursor-help"
        >
          {" "}
          .copyWithin
        </span>
        <span className="mr-0 sm:mr-14 my-3 sm:my-0 sm:w-12 h-8 bracket rounded-full text-2xl text-center outs">
          2, 1
        </span>
        <Arrow />
        <Square />
        <Circle />
        <Circle />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="copyWithinMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "copyWithinMethod" ? (
        <Modals name={copyWithinMethod} close={closeModal} />
      ) : null}
      <div
        data-name="push"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Square />
        <Circle />
        <span
          data-tip="The <strong>push()</strong> method adds one or more elements to the end of an array and returns the new length of the array."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .push
        </span>
        <span className="w-8 h-8 mr-0 my-4 sm:mr-14 bracket bg-subaccent dark:bg-darksubaccent drop-shadow border"></span>
        <Arrow />
        <Square />
        <Square />
        <Circle />
        <Square />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="pushMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "pushMethod" ? (
        <Modals name={pushMethod} close={closeModal} />
      ) : null}
      <div
        data-name="reduce"
        className="flex flex-col sm:flex-row bg-floralwhite dark:bg-darkesub p-20 my-10 justify-center items-center drop-shadow rounded-lg"
      >
        <Square />
        <Square />
        <Square />
        <span
          data-tip="The <strong>reduce()</strong> method executes a user-supplied “reducer” callback function on each element of the array, in order, passing in the return value from the calculation on the preceding element. <br/>The final result of running the reducer across all elements of the array is a single value."
          className="font-bold w-auto sm:w-40 mr-0 sm:mr-6 text-right text-3xl cursor-help"
        >
          {" "}
          .reduce
        </span>
        <span className="mr-0 my-4 sm:my-0 sm:mr-14 bracket rounded-full text-2xl text-center outs">
          acc + curr
        </span>
        <Arrow />
        <div className="w-32 block flex justify-center sm:inline-block">
          <div className=" h-20 w-20 bg-emerald-600 dark:bg-thirdcolor rotate-45 transform border-solid border-4 border-borderaccent drop-shadow"></div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="invisible sm:visible m-3 h-7 w-7 absolute right-2 bottom-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={openModal}
          data-modal="reduceMethod"
          data-tip="Code example"
          data-class="modal-code-example"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          />
        </svg>
      </div>
      {showModal === "reduceMethod" ? (
        <Modals name={reduceMethod} close={closeModal} />
      ) : null}
      <div className="no-result py-10">
        <h4 className="font-bold">
          Sorry, there is no array method with that name!
        </h4>
      </div>
    </>
  );
}
