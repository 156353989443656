import "./styles.css";
import Shapes from "./components/Shapes";
import useDarkMode from "./hooks/useDarkMode";
import ReactTooltip from "react-tooltip";

export default function App() {
  const [colorTheme, setTheme] = useDarkMode();
  return (
    <>
      <div className="App w-11/12 3xl:w-9/12 m-auto dark:text-white relative font-body">
        <div className="bg-floralwhite flex dark:bg-darkesub absolute right-0 p-2 rounded-lg cursor-pointer">
          {colorTheme === "light" ? (
            <>
              <svg
                onClick={() => setTheme(colorTheme)}
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 fill-slate-50"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
              <ReactTooltip
                className="tooltipo"
                type="light"
                effect="float"
                place="top"
                border={true}
                borderColor="#000"
                backgroundColor="#fff"
                textColor="#000"
                html={true}
              />
            </>
          ) : (
            <>
              <svg
                onClick={() => setTheme(colorTheme)}
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
              >
                <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
              </svg>
              <ReactTooltip
                className="tooltipo"
                type="light"
                effect="float"
                place="top"
                border={true}
                borderColor="#ccc"
                backgroundColor="#000"
                textColor="#fff"
                html={true}
              />
            </>
          )}
        </div>
        <h1 className="text-3xl font-bold my-20">
          JavaScript array methods [visualized] <span>👀</span>
        </h1>
        <Shapes />
      </div>
    </>
  );
}
