import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function Modals(props) {
  // const closeModal = () => {
  //   setShowModal("");
  // };
  return (
    <>
      <div
        className="flex dark:bg-neutral-500/50 bg-slate-300/60 overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-0 bottom-0 justify-center z-50"
        id="popup-modal"
      >
        <div className="relative flex items-center px-4 w-3/5 h-full justify-center">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 text-left">
              <button
                className="absolute -right-2 -top-2"
                onClick={props.close}
              >
                <span className="text-black border-2 items-center flex justify-center dark:bg-black dark:text-white border-slate-300 h-6 w-6 text-lg block bg-white py-0 rounded-full">
                  x
                </span>
              </button>
              <SyntaxHighlighter language="javascript" style={dracula}>
                {props.name}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
